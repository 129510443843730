import {useEffect, useState} from "react";
import {debounce} from "../utils/constants/PagesConstants";

export enum ScrollState {
    Scrolling,
    Idle
}


const useWindowScrolling = (): ScrollState => {

    const [scrollState, setScrollState] = useState(ScrollState.Idle)

    useEffect(() => {
        const listener = (scrollState) => {
            debounce(() => {
                setScrollState(scrollState)
            }, 2000)()
        }

        const scrollListener = () => {
            listener(ScrollState.Scrolling)
        }

        const scrollEndListener = () => {
            listener(ScrollState.Idle)
        }

        window.addEventListener('scroll', scrollListener, false)
        window.addEventListener('scrollend', scrollEndListener, false)

        return () => {
            window.removeEventListener('scroll', scrollListener)
            window.removeEventListener('scrollend', scrollEndListener)
        }
    }, [])

    return scrollState
}

export default useWindowScrolling