"use client";
import { useRef, useState, useEffect } from "react";
import Analytics from "../../../lib/analytics/Analytics";
import {
  HowToVideoOpen,
  MuteHowToVideo,
  HowToVideoPlayback,
  HowToVideoClose,
} from "../../../lib/analytics/AnalyticsEvents";
import {
  useDownloadContext,
  DownloadButtonState,
} from "../../../lib/context/CommonContext";
import {
  IFloatingDownloadButtonComponentProps,
  IFixedDownloadButtonProps,
} from "../../../lib/types/components";
import {
  GET_APP_FACEBOOK_URL,
  APK_DOWNLOAD_AGAIN_DELAY,
  FIXED_DOWNLOAD_BUTTON_TYPE,
  HOME_PAGE_URL,
} from "../../../lib/utils/constants/GlobalConstants";
import {
  checkIsGetAppFb,
  isiOSDevice,
  setIsHowToVideoAvailable,
  isMobile,
} from "../../../lib/utils/constants/PagesConstants";
import { multipleClassName } from "../../../lib/utils/helpers/helper";
import useIsIOS from "../../../lib/utils/helpers/useIsIOS";
import CustomImageComponent from "../../common/CustomImage/CustomImageComponent";
import styles from "./FloatingDownloadButton.module.css";
import CustomLinkComponent from "../../common/CusotmLink/CustomLinkComponent";
import FixedDownloadButton from "../FixedDownloadButton/FixedDownloadButton";

const FloatingDownloadButtonWithVideo = (
  props: IFloatingDownloadButtonComponentProps
) => {
  const {
    fixedDownloadButtonData,
    blinkTextDownload,
    blinkTextDownload2,
    pageName,
    isMute = false,
  } = props;

  const videoTag = useRef<HTMLVideoElement>(null);
  let isIos = useIsIOS();
  const isGetAppFb: boolean = checkIsGetAppFb(pageName);
  const isGetAppFacebook: boolean = pageName === GET_APP_FACEBOOK_URL;
  const isHomePage: boolean = pageName === HOME_PAGE_URL;
  const [loadVideo, setLoadVideo] = useState<boolean>(false);
  const [showVideo, setShowVideo] = useState<boolean | null>(null);
  const [mute, setMute] = useState<boolean>(isMute);
  const { downloadButtonState, setDownloadButtonState } = useDownloadContext();
  const toggleOverflowProperty = () => {
    const html: HTMLHtmlElement | null = document.querySelector("html");
    if (html !== null) {
      html.style.overflowY = showVideo && !isiOSDevice ? "hidden" : "auto";
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined = undefined;
    // if (isGetAppFacebook) {
    if (downloadButtonState === DownloadButtonState.DownloadClicked) {
      if (showVideo === null) {
        setShowVideo(true);
        Analytics.getInstance().logEvent(HowToVideoOpen);
      }
    } else if (downloadButtonState === DownloadButtonState.Downloading) {
      if (!showVideo && showVideo === null) {
        setShowVideo(true);
        Analytics.getInstance().logEvent(HowToVideoOpen);
      }

      timer = setTimeout(() => {
        // setIsApkDownloading(false);
        setDownloadButtonState(DownloadButtonState.DownloadAgain);
      }, APK_DOWNLOAD_AGAIN_DELAY);
    }
    return () => clearTimeout(timer);
  }, [downloadButtonState]);

  // useEffect to call initial function when page is mounted on DOM
  useEffect(() => {
    setIsHowToVideoAvailable(true);
    setLoadVideo(true);
  }, []);

  useEffect(() => {
    isMobile && toggleOverflowProperty();
    if (showVideo && isIos === false) {
      const html: HTMLHtmlElement | null = document.querySelector("html");
      if (html !== null && !isGetAppFb && !isGetAppFacebook && !isHomePage) {
        html.style.scrollBehavior = "initial";
        isMobile &&
          document.getElementById("howToInstallGermany")?.scrollIntoView({
            behavior: "smooth",
          });
      }
      if (videoTag.current) {
        videoTag.current.currentTime = 0;
        videoTag.current.play();
      }
    } else {
      videoTag.current?.pause();
    }
    return () => {
      videoTag.current?.pause();
      const html: HTMLHtmlElement | null = document.querySelector("html");
      if (html !== null) {
        html.style.overflowY = "auto";
      }
    };
  }, [showVideo, videoTag.current]);

  const handleVideoMute = (isMute: boolean) => {
    setMute(isMute);
    Analytics.getInstance().logEvent(MuteHowToVideo, {
      mute: isMute,
    });
  };

  const handleVideoPlayback = (event) => {
    event.preventDefault();
    let isVideoPlay = false;
    if (videoTag?.current?.paused) {
      videoTag?.current?.play();
      isVideoPlay = true;
    } else {
      videoTag?.current?.pause();
    }
    if (mute) {
      setMute(false);
    }
    Analytics.getInstance().logEvent(HowToVideoPlayback, {
      isPlay: isVideoPlay,
    });
  };

  const fixedDownloadBtnProps: IFixedDownloadButtonProps = {
    type: FIXED_DOWNLOAD_BUTTON_TYPE,
    fixedDownloadButtonData: fixedDownloadButtonData,
    pageName: pageName,
  };

  return (
    <>
      <div
        className={multipleClassName(
          "floatingDownloadButtonContainer",
          styles.floatingDownloadButtonContainer,
          "floatingDownloadButtonContainerGermany"
        )}
      >
        <div
          className={multipleClassName(
            styles.floatingDownloadButton,
            isGetAppFb || isGetAppFacebook || isHomePage
              ? styles.floatingDownloadButtonFB
              : ""
          )}
          style={{ display: showVideo && !isiOSDevice ? "block" : "none" }}
        >
          <div style={{ height: "100%" }}>
            <div
              className={styles.offerPopupClose}
              onClick={() => {
                Analytics.getInstance().logEvent(HowToVideoClose);
                setShowVideo(false);
              }}
            >
              <CustomImageComponent
                src={
                  "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/close_offer_popup.svg"
                }
                width={24}
                height={24}
                alt="offer-close-popup"
                layout="fixed"
              />
            </div>
            {loadVideo && (
              <>
                <video
                  poster={props.howToInstallThumbnail}
                  src={props.howToInstallVideo}
                  className={multipleClassName(
                    styles.videoView,
                    isGetAppFb || isGetAppFacebook || isHomePage ? styles.videoViewFB : ""
                  )}
                  preload="none"
                  loop
                  ref={videoTag}
                  onVolumeChange={(event) => {
                    handleVideoMute(videoTag?.current?.muted || false);
                  }}
                  playsInline
                  muted={mute}
                  controls
                  disablePictureInPicture
                  controlsList="noplaybackrate nodownload nofullscreen"
                  onClick={(event) => {
                    handleVideoPlayback(event);
                  }}
                  onTouchEnd={(event) => {
                    handleVideoPlayback(event);
                  }}
                />
              </>
            )}
            <div
              className={styles.videoSoundControlView}
              onClick={() => setMute(!mute)}
            >
              <CustomImageComponent
                src={
                  mute
                    ? "https://d3g4wmezrjkwkg.cloudfront.net/website/images/mute.webp"
                    : "https://d3g4wmezrjkwkg.cloudfront.net/website/images/unmute.webp"
                }
                width={36}
                height={36}
                alt="offer-close-popup"
                layout="fixed"
              />
            </div>
          </div>
        </div>

        <div className={styles.downloadParentContainer}>
          <FixedDownloadButton
            {...fixedDownloadBtnProps}
            storyFlow={true}
            showAnimation={true}
          />
        </div>

        {fixedDownloadButtonData.floatingComponent && (
          <div className={multipleClassName("row", styles.pageBottomContainer)}>
            <div className={styles.imageParentContainer}>
              <div className={styles.imageContainer}>
                <CustomImageComponent
                  src={
                    "https://d3g4wmezrjkwkg.cloudfront.net/website/images/brazilWebsite/download_logo.webp"
                  }
                  alt="logo"
                  objectFit={"contain"}
                  objectPosition={"center bottom"}
                  layout="fill"
                />
              </div>
            </div>

            <div className={multipleClassName("col-4", styles.linksContainer)}>
              <CustomLinkComponent
                href={fixedDownloadButtonData.floatingComponent[0].url}
              >
                <div
                  className={styles.floatingParentContainer}
                  style={{ marginLeft: "20px" }}
                >
                  <div className={styles.floatingImgContainer}>
                    <CustomImageComponent
                      src={fixedDownloadButtonData.floatingComponent[0].img}
                      alt="floating_img"
                      objectFit={"contain"}
                      objectPosition={"center center"}
                      layout="fill"
                    />
                  </div>
                  <p style={{ margin: 0 }}>
                    {fixedDownloadButtonData.floatingComponent[0].text}
                  </p>
                </div>
              </CustomLinkComponent>
            </div>
            <div className={multipleClassName("col-4", styles.linksContainer)}>
              <CustomLinkComponent href="#" />
            </div>
            <div className={multipleClassName("col-4", styles.linksContainer)}>
              <CustomLinkComponent
                href={fixedDownloadButtonData.floatingComponent[1].url}
              >
                <div
                  className={styles.floatingParentContainer}
                  style={{ marginRight: "20px" }}
                >
                  <div className={styles.floatingImgContainer}>
                    <CustomImageComponent
                      src={fixedDownloadButtonData.floatingComponent[1].img}
                      alt="floating_img"
                      objectFit={"contain"}
                      objectPosition={"center center"}
                      layout="fill"
                    />
                  </div>
                  <p style={{ margin: 0 }}>
                    {fixedDownloadButtonData.floatingComponent[1].text}
                  </p>
                </div>
              </CustomLinkComponent>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FloatingDownloadButtonWithVideo;
