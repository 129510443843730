import React from "react";
import { IAboutUsComponentProps } from "../../../lib/types/components";
import styles from "./AboutUsComponent.module.css";
import parse from "html-react-parser";
import { multipleClassName } from "../../../lib/utils/helpers/helper";
import CustomImageComponent from "../../common/CustomImage/CustomImageComponent";

const AboutUsComponent = (props: IAboutUsComponentProps) => {
  const aboutUsData = props.aboutUsData;
  return aboutUsData ? (
    <div className={styles.parentContainer}>
      <div className={styles.bgImages} />
      <div className="container" style={{ position: "relative" }}>
        <div className={styles.waterMarkAndTextContainer}>
          <div className={styles.waterMarkNoWrap}>{aboutUsData.watermark}</div>
          <p className={styles.subHeading}>{aboutUsData.subHeading}</p>
          <h2 className={styles.heading}>{aboutUsData.heading}</h2>
        </div>
        {aboutUsData.imgV2 && (
          <div className={styles.snapshotImgContainer}>
            <CustomImageComponent
              src={aboutUsData.imgV2}
              alt="Snapshot of Winzo"
              objectFit={"contain"}
              objectPosition={"center center"}
              layout="fill"
            />
          </div>
        )}
        <div className={styles.descContainer}>
          {aboutUsData.para.map((item, idx) => {
            return <p>{item}</p>;
          })}
        </div>

        <h4 className={styles.pointsHeading}>{aboutUsData.dataHeading}</h4>
        <div className={styles.pointsItemContainer}>
          {aboutUsData.data &&
            aboutUsData.data.map((item) => {
              return (
                <div className={styles.pointsContainer}>
                  <div className={styles.pointImageContainer}>
                    <CustomImageComponent
                      src={item.image}
                      alt="about winzo"
                      objectFit={"contain"}
                      objectPosition={"center center"}
                      layout="fill"
                    />
                  </div>
                  {item.title && (
                    <p className={styles.pointDesc}>{parse(item.title)}</p>
                  )}
                  {item.titleMob && (
                    <p
                      className={multipleClassName(
                        styles.pointDesc,
                        styles.pointDescMob
                      )}
                    >
                      {parse(item.titleMob)}
                    </p>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default AboutUsComponent;
