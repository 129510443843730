"use client";
import React, { useEffect, useRef, useState } from "react";
import { IHowToInstallAppComponentProps } from "../../../lib/types/components";
import styles from "./HowToInstallComponent.module.css";
import Slider, { Settings } from "react-slick";
import useIsMobile from "../../../lib/customHooks/isMobile";
import CustomCarousel from "../../common/CustomCarouselNaviagtion/CustomCarouselNavigation";
import { NextArrow, PrevArrow } from "../ArrowComponent/ArrowComponent";
import CustomImageComponent from "../../common/CustomImage/CustomImageComponent";
import { isiOSDevice } from "../../../lib/utils/constants/PagesConstants";

const HowToInstallComponent = (props: IHowToInstallAppComponentProps) => {
  const howToInstallData = props.howToInstallData;
  const { isMobileDevice } = useIsMobile();
  const [nextSlide, setNextSlide] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [stepsData, setStepsData] = useState(howToInstallData.stepsDataGlobal);
  const sliderRef = useRef<Slider>(null);

  useEffect(() => {
    if (!isMobileDevice && sliderRef) {
      howToInstallData.stepsDataGlobal?.push(
        howToInstallData.stepsDataGlobal[0]
      );
      setTimeout(() => {
        sliderRef.current?.slickNext();
      }, 0);
    } else {
      if (isiOSDevice) {
        setStepsData(howToInstallData.stepsDataIos);
      }
    }
  }, [isMobileDevice]);

  const sliderSettings: Settings = {
    centerMode: true,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    centerPadding: "0px",
    dots: false,
    arrows: true,
    autoplay: false,
    nextArrow: (
      <NextArrow
        onClick
        showInMobile={true}
        active={
          isMobileDevice
            ? nextSlide < stepsData?.length - 1
            : nextSlide < stepsData?.length - 2
        }
      />
    ),
    prevArrow: (
      <PrevArrow
        onClick
        showInMobile={true}
        active={isMobileDevice ? nextSlide > 0 : nextSlide > 1}
      />
    ),
    beforeChange: (currentSlide, nextSlide) => {
      setNextSlide(nextSlide);
    },
    afterChange: (currentSlide) => {
      setCurrentSlide(currentSlide);
    },
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return stepsData ? (
    <div className={styles.parentContainer} id="howToInstallGermany">
      <div className={styles.bgImages} />
      <div className="container" style={{ position: "relative" }}>
        <div className={styles.waterMarkAndTextContainer}>
          <div className={styles.waterMarkNoWrap}>
            {howToInstallData.watermark}
          </div>
          <p className={styles.subHeading}>{howToInstallData.subheading}</p>
          <h2 className={styles.heading}>{howToInstallData.heading}</h2>
        </div>

        <div className={styles.sliderContainer}>
          <Slider {...sliderSettings} ref={sliderRef}>
            {stepsData.map((item, index) => {
              return !isMobileDevice && index === stepsData.length - 1 ? (
                <></>
              ) : (
                <div
                  className={styles.howToInstallItemContainer}
                  key={"how_to_install_germany" + index}
                >
                  <div className={styles.howToInstallImageContainer}>
                    <CustomImageComponent
                      src={item.url}
                      alt="how to install"
                      objectFit={"contain"}
                      objectPosition={"center center"}
                      layout="fill"
                    />
                  </div>
                  <p className={styles.stepNumber}>{item.step}</p>
                  <p className={styles.description}>{item.text}</p>
                </div>
              );
            })}
          </Slider>
        </div>
        <div>
          <CustomCarousel
            nextSlide={nextSlide}
            currentSlide={currentSlide}
            slideCount={howToInstallData.stepsData.length}
            sliderRef={sliderRef}
            hideArrow={true}
            customDotColor="#280042"
          />
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default HowToInstallComponent;
