"use client";
import React, { useCallback, useEffect, useRef } from "react";
import styles from "./CustomCarouselNaviagtion.module.css";
import Slider from "react-slick";
import { multipleClassName } from "../../../lib/utils/helpers/helper";
import CustomImageComponent from "../CustomImage/CustomImageComponent";

interface CustomCarouselProps {
  currentSlide: number;
  nextSlide: number;
  slideCount: number;
  sliderRef: React.RefObject<Slider>;
  hideArrow?: boolean;
  customDotColor?: string;
  customArrowBck?: string;
  customArrowColor?: string;
  customArrowImage?: string;
}

const CustomCarousel: React.FC<CustomCarouselProps> = ({
  currentSlide,
  nextSlide,
  slideCount,
  sliderRef,
  customDotColor,
  hideArrow = false,
  customArrowBck = "",
  customArrowColor = "",
  customArrowImage = "",
}) => {
  const dots = Math.max(slideCount, 5) * 2;
  const containerRef = useRef<HTMLUListElement>(null);
  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      const children = Array.from(container.children);
      const middleIndex = 2 + currentSlide;

      if (
        middleIndex &&
        children[middleIndex] &&
        children[middleIndex].children[0].classList.contains(styles.active) &&
        children[middleIndex - 1] &&
        children[middleIndex + 1]
      ) {
        children[middleIndex].children[0].classList.remove(styles.active);
        children[middleIndex - 1].children[0].classList.remove(styles.nextDot);
        children[middleIndex + 1].children[0].classList.remove(styles.prevDot);
      }
    }
  }, [nextSlide]);

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      const children = Array.from(container.children);
      const middleIndex = 2 + currentSlide;

      if (
        middleIndex &&
        children[middleIndex] &&
        !children[middleIndex].children[0].classList.contains(styles.active) &&
        children[middleIndex - 1] &&
        children[middleIndex + 1]
      ) {
        children[middleIndex].children[0].classList.add(styles.active);
        children[middleIndex - 1].children[0].classList.add(styles.nextDot);
        children[middleIndex + 1].children[0].classList.add(styles.prevDot);
      }
      container.style.transform = `translateX(calc(${currentSlide} * -1 * (clamp(8px, 0.83vw, 16px) + clamp(4px, 0.625vw, 12px))))`;
    }
  }, [currentSlide]);

  const handleNext = useCallback(() => {
    sliderRef.current?.slickNext();
  }, [sliderRef]);

  const handlePrev = useCallback(() => {
    sliderRef.current?.slickPrev();
  }, [sliderRef]);

  const CustomNextPrevArrow = React.memo(
    ({
      onClick,
      arrowType,
      customArrowImage,
    }: {
      onClick: () => void;
      arrowType: "next" | "prev";
      customArrowImage?: string;
    }) => {
      return (
        <div
          onClick={onClick}
          className={multipleClassName(
            styles.zoVenturesGallerySlickArrow,
            customArrowBck,
            styles[arrowType]
          )}
        >
          {customArrowImage ? (
            <div className={styles.customArrowImage}>
              <CustomImageComponent
                src={customArrowImage}
                alt="arrow"
                objectFit={"contain"}
                objectPosition={"center center"}
                layout="fill"
              />
            </div>
          ) : (
            <div
              className={styles.arrowImage}
              style={{ background: customArrowColor || "#fff" }}
            ></div>
          )}
        </div>
      );
    },
    (prevProps, nextProps) =>
      prevProps.arrowType === nextProps.arrowType &&
      prevProps.onClick === nextProps.onClick
  );

  return (
    <div className={styles.root}>
      {!hideArrow && (
        <CustomNextPrevArrow
          onClick={handlePrev}
          arrowType="prev"
          customArrowImage={customArrowImage}
        />
      )}
      <div className={styles.dotsContainer}>
        <ul ref={containerRef}>
          {[...Array(dots)].map((_, index) => (
            <li
              key={index}
              id={`${Math.abs((slideCount - 2 + index) % slideCount)}`}
            >
              <span
                className={multipleClassName(
                  styles.dot,
                  styles[`item${index}`]
                )}
                style={{
                  background: customDotColor,
                  cursor: "pointer",
                }}
                onClick={() => {
                  sliderRef.current?.slickGoTo(
                    Math.abs((slideCount - 2 + index) % slideCount)
                  );
                }}
              ></span>
            </li>
          ))}
        </ul>
      </div>
      {!hideArrow && (
        <CustomNextPrevArrow
          onClick={handleNext}
          arrowType="next"
          customArrowImage={customArrowImage}
        />
      )}
    </div>
  );
};

export default CustomCarousel;
