"use client"
import Slider from "react-slick";
import H2TrapeziumBG from "../../common/TrapeziumBackground/H2TrapeziumBG";
import {IWinzoWinnerComponentProps} from '../../../lib/types/components';
import CustomImageComponent from '../../common/CustomImage/CustomImageComponent';
import {useEffect, useRef} from "react";
import {multipleClassName} from "../../../lib/utils/helpers/helper";
import styles from "./WinzoWinnerComponentV2.module.css"
import {isiOSDevice} from "../../../lib/utils/constants/PagesConstants";

const WinzoWinnerComponentV2 = (props: IWinzoWinnerComponentProps) => {

    let {winzoWinnerData} = props;
    let winnerSlider = useRef<Slider>(null)
    const sliderSettings = {
        className: "sliderContainerWinZOWinners center",
        centerMode: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerPadding: "0px",
        arrows: false,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    infinite: false,
                    centerPadding: "20px",
                    slidesToShow: 1
                }
            },
        ]
    }

    useEffect(() => {
        if (winnerSlider.current) {
            winnerSlider.current.slickNext()
        }
    }, [])

    return (
        winzoWinnerData && Object.keys(winzoWinnerData).length ?
            <H2TrapeziumBG>
                <div className={multipleClassName("container-fluid", styles.parentContainer)} style={winzoWinnerData.userCardData.length ? {
                    backgroundImage: `url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/winzo_winner_leftV2.webp"), url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/winzo_winner_rightV2.webp")`
                } : {}}>
                    <div className={multipleClassName("container", styles.mainContainer)} id="id_winzo_winners">
                        {winzoWinnerData.userCardData.length ? <div className={styles.upperQuoteImageContainer}>
                            <div className={styles.upperQuoteImage}>
                                <CustomImageComponent
                                    layout={'fill'}
                                    objectFit={'contain'}
                                    src={'https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/quote_imageV2.svg'}
                                    alt={"quote image"}
                                />
                            </div>
                        </div> : <></>}
                        {winzoWinnerData.userCardData.length ? <div className={styles.lowerQuoteImageContainer}>
                            <div className={styles.lowerQuoteImage}>
                                <CustomImageComponent
                                    layout={'fill'}
                                    objectFit={'contain'}
                                    src={'https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/quote_imageV2.svg'}
                                    alt={"quote image"}
                                />
                            </div>
                        </div> : <></>}

                        <div className={styles.headingParent}>
                            <h2 className={styles.heading}>{winzoWinnerData.heading}</h2>
                        </div>

                        <div className={styles.sliderContainer}
                             style={isiOSDevice ? {paddingBottom: 20} : {paddingBottom: 0}}>
                            <div style={{width: "100%"}}>
                                <Slider ref={winnerSlider} {...sliderSettings}>
                                    {
                                        winzoWinnerData.userCardData.map((item, index) => {
                                            return (
                                                <div className={multipleClassName("slideParentContainer", styles.slideParentContainer)}
                                                     key={"user_card_data_" + index}>
                                                    <div className={multipleClassName("slideBorderContainer", styles.slideBorderContainer)}>
                                                        <div className={styles.slide}>
                                                            <div className={styles.userImgContainer}>
                                                                <CustomImageComponent
                                                                    layout={'fill'}
                                                                    objectFit={'cover'}
                                                                    src={item.userImg}
                                                                    alt={"winzo-winners-user-image"}
                                                                    className={styles.winzoWinnerImage}
                                                                />
                                                            </div>
                                                            <div className={styles.userDetails}>
                                                                <div className={styles.userName}>
                                                                    {item.userName}
                                                                </div>
                                                                <div className={styles.amountWon}>
                                                                    {item.priceWon}
                                                                </div>

                                                            </div>
                                                            <div className={styles.userDescription}>
                                                                <div>
                                                                    {item.desc}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>

            </H2TrapeziumBG> : <></>
    )
}

export default WinzoWinnerComponentV2;