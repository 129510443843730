"use client";
import React, { useRef, useState } from "react";
import { IUserRatingComponentProps } from "../../../lib/types/components";
import styles from "./TrustedByEveryone.module.css";
import CustomImageComponent from "../../common/CustomImage/CustomImageComponent";
import Slider, { Settings } from "react-slick";
import useIsMobile from "../../../lib/customHooks/isMobile";
import CustomCarousel from "../../common/CustomCarouselNaviagtion/CustomCarouselNavigation";
import { multipleClassName } from "../../../lib/utils/helpers/helper";

const TrustedByEveryone = (props: IUserRatingComponentProps) => {
  const { isMobileDevice } = useIsMobile();
  const [nextSlide, setNextSlide] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef<Slider>(null);
  const customerReviewData = props.customerReviewData;
  const userCardsData =
    props.winzoWinnerCompProps!.winzoWinnerData!.userCardData;
  const sliderSettings: Settings = {
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    centerPadding: "0px",
    dots: false,
    arrows: false,
    autoplay: false,

    beforeChange: (currentSlide, nextSlide) => {
      setNextSlide(nextSlide);
    },
    afterChange: (currentSlide) => {
      setCurrentSlide(currentSlide);
    },
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          centerPadding: "40px",
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          centerPadding: "40px",
          autoplay: false,
          autoplaySpeed: 3000,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          centerPadding: "20px",
          autoplay: false,
          autoplaySpeed: 3000,
        },
      },
    ],
  };
  return (
    <div className={styles.parentContainer}>
      <div className={styles.bgImages} />
      <div className="container" style={{ position: "relative" }}>
        <div className={styles.waterMarkAndTextContainer}>
          <div className={styles.waterMarkNoWrap}>
            {customerReviewData.watermark}
          </div>
          <p className={styles.subHeading}>{customerReviewData.subHeading}</p>
          <h2 className={styles.heading}>{customerReviewData.heading}</h2>
        </div>
        <div className={styles.mainContainer}>
          <div className={styles.iconParentContainer}>
            <div className={styles.iconContainer}>
              <CustomImageComponent
                src={
                  "https://d3g4wmezrjkwkg.cloudfront.net/website/images/brazilWebsite/app_store.png"
                }
                alt="app store"
                objectFit={"contain"}
                objectPosition={"bottom center"}
                layout="fill"
              />
            </div>
          </div>
          <div className={styles.ratingContainer}>
            <h4 className={styles.ratingNumber}>{customerReviewData.text1}</h4>
            <div className={styles.ratingStarContainer}>
              {[...Array(5)].map((x, i) => {
                let imgSrc =
                  "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/";
                imgSrc += i === 4 ? "halfStarV2.svg" : "starV2.svg";
                return (
                  <div className={styles.ratingStars} key={"rating_" + i}>
                    <CustomImageComponent
                      src={imgSrc}
                      layout={"fill"}
                      alt={"star"}
                    />
                  </div>
                );
              })}
            </div>
            <p className={styles.ratingDesc}>{customerReviewData.text2}</p>
          </div>
          <div className={styles.reviewParentContainer}>
            <Slider {...sliderSettings} ref={sliderRef}>
              {userCardsData.map((item, idx) => {
                return (
                  <div
                    key={"user_card_de" + idx}
                    className={multipleClassName(
                      styles.cardContainer,
                      "trustedByGermany"
                    )}
                  >
                    <div className={styles.cardMainContainer}>
                      <div className={styles.cardHeadingContainer}>
                        <div className={styles.profileContainer}>
                          <CustomImageComponent
                            src={item.userImg}
                            alt="profile pic"
                            objectFit={"cover"}
                            objectPosition={"center center"}
                            layout="fill"
                          />
                        </div>
                        <div className={styles.nameContainer}>
                          <p className={styles.userName}>{item.userName}</p>
                          <p className={styles.reviewDate}>{item.priceWon}</p>
                        </div>
                        <div className={styles.starsContainer}>
                          {item.userRating &&
                            [...Array(5)].map((x, i) => {
                              let imgSrc =
                                "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/";

                              imgSrc +=
                                i < Math.floor(item.userRating!)
                                  ? "starV2.svg"
                                  : i >= Math.ceil(item.userRating!)
                                  ? "empty_star.webp"
                                  : "halfStarV2.svg";
                              return (
                                <div
                                  className={styles.userRatingStars}
                                  key={"rating_" + i}
                                >
                                  <CustomImageComponent
                                    src={imgSrc}
                                    layout={"fill"}
                                    alt={"star"}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      <p className={styles.descContainer}>{item.desc}</p>
                      <p className={styles.summary}>{item.summary}</p>
                    </div>
                  </div>
                );
              })}
            </Slider>
            <div>
              <CustomCarousel
                nextSlide={nextSlide}
                currentSlide={currentSlide}
                slideCount={userCardsData.length}
                sliderRef={sliderRef}
                hideArrow={true}
                customDotColor="#280042"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrustedByEveryone;
