"use client"
import useIsMobile from "../../../lib/customHooks/isMobile";
import { multipleClassName } from "../../../lib/utils/helpers/helper";
import CustomImageComponent from "../../common/CustomImage/CustomImageComponent";
import styles from "./ArrowComponent.module.css";

export const PrevArrow = ({
  onClick,
  showInMobile = false,
  active = true,
  dark = true,
  top = "0",
}) => {
  const { isMobileDevice } = useIsMobile();

  return (
    <div
      className={multipleClassName(styles.arrow, styles.prevArrow)}
      style={
        isMobileDevice === true
          ? {
              display: `${showInMobile ? "flex" : "none"}`,
              top: top,
            }
          : { top: top }
      }
      onClick={active ? onClick : () => {}}
    >
      <CustomImageComponent
        src={
          dark
            ? active
              ? "https://d3g4wmezrjkwkg.cloudfront.net/website/images/brazilWebsite/right_dark_active.png"
              : "https://d3g4wmezrjkwkg.cloudfront.net/website/images/brazilWebsite/right_dark_inactive_arrow.png"
            : active
            ? "https://d3g4wmezrjkwkg.cloudfront.net/website/images/brazilWebsite/right_light_active.png"
            : "https://d3g4wmezrjkwkg.cloudfront.net/website/images/brazilWebsite/right_light_inactive.png"
        }
        alt={"winner-quotes-image"}
      />
    </div>
  );
};

export const NextArrow = ({
  onClick,
  showInMobile = false,
  active = true,
  dark = true,
  top = "0",
}) => {
  const { isMobileDevice } = useIsMobile();
  return (
    <div
      className={multipleClassName(styles.arrow, styles.nextArrow)}
      style={
        isMobileDevice === true
          ? {
              display: `${showInMobile ? "flex" : "none"}`,
              top: top,
            }
          : { top: top }
      }
      onClick={active ? onClick : () => {}}
    >
      <CustomImageComponent
        src={
          dark
            ? active
              ? "https://d3g4wmezrjkwkg.cloudfront.net/website/images/brazilWebsite/right_dark_active.png"
              : "https://d3g4wmezrjkwkg.cloudfront.net/website/images/brazilWebsite/right_dark_inactive_arrow.png"
            : active
            ? "https://d3g4wmezrjkwkg.cloudfront.net/website/images/brazilWebsite/right_light_active.png"
            : "https://d3g4wmezrjkwkg.cloudfront.net/website/images/brazilWebsite/right_light_inactive.png"
        }
        alt={"image"}
      />
    </div>
  );
};
