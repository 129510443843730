import { getWebsiteEnvironment } from "../utils/constants/EnvironmentConstants";

export default function SiteNavigationSchema() {
    return (
        <>
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: `{
                        "@context": "http://schema.org",
                        "@type": "ItemList",
                        "itemListElement": [
                          {
                            "@type": "SiteNavigationElement",
                            "name": "Ludo",
                            "url": "https://${getWebsiteEnvironment().baseUrl}/de-de/all-games/ludo"
                          },
                          {
                            "@type": "SiteNavigationElement",
                            "name": "Solitar",
                            "url": "https://${getWebsiteEnvironment().baseUrl}/de-de/all-games/solitaire-game"
                          },
                          {
                            "@type": "SiteNavigationElement",
                            "name": "Fruit Samurai",
                            "url": "https://${getWebsiteEnvironment().baseUrl}/de-de/all-games/fruit-samurai"
                          },
                          {
                            "@type": "SiteNavigationElement",
                            "name": "Metro Surfer",
                            "url": "https://${getWebsiteEnvironment().baseUrl}/de-de/all-games/metro-surfer"
                          },
                          {
                            "@type": "SiteNavigationElement",
                            "name": "WinZO Blog",
                            "url": "https://${getWebsiteEnvironment().baseUrl}/de-de/blog/"
                          }
                        ]
                    }`,
                }}
            />
        </>
    )
}