import {IUserRatingComponentProps} from '../../../lib/types/components';
import CustomImageComponent from '../CustomImage/CustomImageComponent';
import styles from "./UserRatingComponentV2.module.css"
import {multipleClassName} from "../../../lib/utils/helpers/helper";

const UserRatingComponentV2 = (props: IUserRatingComponentProps) => {
    const {customerReviewData, winzoWinnerCompProps} = props;

    const RatingProgressBar = (props: { percentage: number, color: string, value: number }) => {
        const {color, value} = props;

        return (
            <div className={styles.ratingProgress}>
                <div
                    className={styles.ratingProgressBar}
                    style={{width: `${value}%`, backgroundColor: color}}
                >
                </div>
            </div>

        );
    };

    return (
        customerReviewData && Object.keys(customerReviewData).length ?
            <div className={multipleClassName("container-fluid", styles.parentRatingContainer)}>
                <div className={multipleClassName("container", styles.styleContainer)} id="id_why_winzo">
                    <h2 className={styles.heading}>{customerReviewData.heading}</h2>
                    <div className={styles.ratingContainer}>
                        <div className={styles.totalRating}>
                            <div className={styles.ratingHeading}>{customerReviewData.text1}</div>
                            <div className={styles.ratingStarsContainer}>{
                                [...Array(5)].map((x, i) => {
                                    let imgSrc = "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/";
                                    imgSrc += i === 4 ? "halfStarV2.svg" : "starV2.svg";
                                    return (
                                        <div className={styles.ratingStars} key={"rating_" + i}>
                                            <CustomImageComponent
                                                src={imgSrc}
                                                layout={'fill'}
                                                alt={"star"}
                                            />
                                        </div>
                                    )
                                })
                            }</div>
                            <div className={styles.ratingDesc}>{customerReviewData.text2}</div>
                        </div>
                        <div className={styles.ratingInformation}>
                            {
                                [...Array(5)].map((x, i) => {
                                    const currentRating = 5 - i;
                                    let percent;
                                    let color;
                                    switch (currentRating) {
                                        case 5:
                                            percent = 79;
                                            color = "#39b061"
                                            break;
                                        case 4:
                                            percent = 15;
                                            color = "#92e042"
                                            break;
                                        case 3:
                                            percent = 4;
                                            color = "#f4d249"
                                            break;
                                        case 2:
                                            percent = 1;
                                            color = "#fbb851"
                                            break;
                                        case 1:
                                            percent = 1;
                                            color = "#f17a54"
                                            break;

                                    }
                                    return (
                                        <div className={styles.ratingInfoRow} key={"rating_info_" + i}>
                                            <div className={styles.ratingNumber}>
                                                {currentRating}
                                            </div>
                                            <div className={styles.ratingInfoStarsContainer}>
                                                {
                                                    [...Array(5)].map((y, j) => {
                                                        let imgSrc = "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/";
                                                        imgSrc += j < currentRating ? "starV2.svg" : "emptyStarV2.svg";
                                                        return (
                                                            <div className={styles.ratingInfoStars}
                                                                 key={"rating_info_stars_" + j}>
                                                                <CustomImageComponent
                                                                    src={imgSrc}
                                                                    layout={'fill'}
                                                                    alt={"star"}
                                                                />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className={styles.ratingBar}>
                                                <div className={styles.progressBar}>
                                                    <RatingProgressBar
                                                        percentage={percent}
                                                        color={color}
                                                        value={percent}
                                                    />
                                                </div>
                                                <div className={styles.percentageContainer}>
                                                    {`${percent}%`}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            :
            <></>
    )
}

export default UserRatingComponentV2;