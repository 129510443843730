import H2TrapeziumBG from "../TrapeziumBackground/H2TrapeziumBG";
import {IMemberOfComponentProps} from "../../../lib/types/components";
import CustomImageComponent from '../CustomImage/CustomImageComponent';
import {multipleClassName} from "../../../lib/utils/helpers/helper";
import styles from "./MemberOfComponentV2.module.css"

const MemberOfComponentV2 = (props: IMemberOfComponentProps) => {

    const {membersData} = props;

    return (
        membersData && Object.keys(membersData).length ?
            <H2TrapeziumBG uniqueContainerId={"memberOf_container"}>
                <div className={multipleClassName("container-fluid", styles.memberContainerV2)}>
                    <div className="container">
                        <div className={styles.headingParent}>
                            <h2 className={styles.heading}>{membersData.headingV2}</h2>
                        </div>
                        <div className={multipleClassName("row", styles.contentContainer)}>
                            <div className={multipleClassName("col-md-6", "col-12", styles.memberOfContainer)}>
                                {/* {membersData.listV2.map((item, index) => {
                                    return ( */}
                                <div className={styles.imageParent} key={"memberOfContainer_"}>
                                    <div className={styles.imageContainer}>
                                        {membersData.image && <CustomImageComponent
                                            src={membersData.image}
                                            alt={"memberof"} layout={"fill"}
                                            objectFit={"contain"}/>}
                                        {(membersData.urlGLC && membersData.imageGLC) &&
                                                <a href={membersData.urlGLC} target="_blank" rel="noreferrer noopener" style={{display: "block"}}>
                                                <div className={styles.subImageContainerGLC}>
                                                <CustomImageComponent src={membersData.imageGLC || 'https://dkees1a826nmf.cloudfront.net/productionbackoffice/website/GamingLabsCertified-1721643700672.webp'}
                                                                      alt={"memberof"} layout="fill" objectFit={"contain"}
                                                /></div>
                                                </a>
                                                }
                                        {membersData.imageIEIC &&
                                            <div className={styles.subImageContainerIEIC}>
                                            <CustomImageComponent src={membersData.imageIEIC}
                                                                  alt={"memberof"} layout="fill" objectFit={"contain"}
                                            /></div>}
                                        {membersData.imageFICCI &&
                                        <div className={styles.subImageContainerFICCI}>
                                            <CustomImageComponent src={membersData.imageFICCI}
                                                                  alt={"memberof"} layout="fill" objectFit={"contain"}
                                            /></div>}
                                    </div>
                                </div>

                                {/* })} */}
                            </div>
                            <div className={multipleClassName("col-md-6", "col-12", styles.featuresContainer)}>
                                <div className={styles.featureItemParent}>
                                    {membersData.features.map((item, index) => {
                                        return (
                                            <div className={styles.featureItemContainer}
                                                 key={"featureItemParent_" + index}>
                                                <div className={styles.itemImageParent}>
                                                    <div className={styles.itemImageContainer}>
                                                        <CustomImageComponent src={item.image.src} alt={item.image.alt}
                                                                              layout={"fill"} objectFit={"contain"}/>
                                                    </div>
                                                </div>
                                                <div className={styles.featureItem} title={item.heading}>
                                                    {item.heading}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </H2TrapeziumBG> : <></>
    )
}

export default MemberOfComponentV2;