import {IWithdrawalPartnersComponentProps} from "../../../lib/types/components";
import {multipleClassName} from "../../../lib/utils/helpers/helper";
import styles from "./WithdrawalPartnersComponentV2.module.css"

const WithdrawalPartnersComponentV2 = (props: IWithdrawalPartnersComponentProps) => {
    const {withdrawalPartnerData, isGetApp} = props;
    return (
        withdrawalPartnerData && Object.keys(withdrawalPartnerData).length ?
            <div className={multipleClassName("container-fluid", styles.withdrawalContainer)}>
                <div className={"container"}>
                    {/* <div className={styles.headingParent}>
                            <h2 className={styles.heading}>{withdrawalPartnerData?.text1}</h2>
                        </div>
                        <div className={multipleClassName("row", styles.imagesRow)}>
                            {withdrawalPartnerData.images.map((item, idx) => {
                                return (
                                    <div className={multipleClassName("col", styles.imagePartnerContainer)}>
                                        <div className={styles.imageParent}>
                                            <div className={styles.imageContainer}>
                                                <CustomImageComponent src={item.srcV2} alt={item.alt} layout={"fill"}
                                                                      objectFit={"contain"}/>
                                            </div>
                                        </div>

                                    </div>
                                )
                            })}
                        </div> */}
                </div>
            </div> :
            <div style={{minHeight: "4.6vw"}}></div>
    )
}

export default WithdrawalPartnersComponentV2;