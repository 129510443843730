import H2TrapeziumBG from "../../common/TrapeziumBackground/H2TrapeziumBG";
import {IWinzoStoreOfferComponentProps} from "../../../lib/types/components";
import CustomImageComponent from '../../common/CustomImage/CustomImageComponent';
import DottedGrid from "../../common/Utils/DottedGrid";
import {multipleClassName} from "../../../lib/utils/helpers/helper";
import styles from "./WinzoStoreOfferComponentV2.module.css"

const WinzoStoreOfferComponentV2 = (props: IWinzoStoreOfferComponentProps) => {

    const {winzoStoreData} = props;

    return (
        winzoStoreData && Object.keys(winzoStoreData).length ?
            <H2TrapeziumBG uniqueContainerId={"winzoStore_container"}>
                <div className={multipleClassName("container-fluid", styles.mainContainer)}>
                    <DottedGrid color={"#d8d8d8"} lines={2} cssMobileStyle={{
                        display: "none"
                    }} cssDesktopStyle={{
                        display: "block",
                        position: "absolute",
                        top: "5vw",
                        left: "3vw",
                        zIndex: 1000,
                    }}/>
                    <div className={"container"}>
                        <div className={multipleClassName("row", styles.headingParent)}>
                            <h2 className={styles.heading}>{winzoStoreData.heading}</h2>
                        </div>
                        <div className={styles.sliderContainer}>
                            <div className={styles.innerSliderContainer}>
                                {
                                    winzoStoreData.imageData.map((item, index) => {
                                        return (
                                            <div key={"winzoStoreData.imageData_" + index} className={styles.sliderItem}>
                                                <div className={styles.imgContainer}>
                                                    <CustomImageComponent
                                                        src={item.src}
                                                        alt={`${item.text1} Offers`}
                                                        layout={'fill'}
                                                        objectFit={'contain'}
                                                    />
                                                </div>
                                                <p className={styles.itemName}>{item.text1}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </H2TrapeziumBG>
            :
            <></>
    );
}

export default WinzoStoreOfferComponentV2;
