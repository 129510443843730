"use client"
import Slider from "react-slick";
import H2TrapeziumBG from "../../common/TrapeziumBackground/H2TrapeziumBG";
import { IHowToInstallAppComponentProps } from "../../../lib/types/components";
import { dynamicCssSize } from "../../../lib/utils/constants/PagesConstants";
import { NextArrowV2, PrevArrowV2 } from "../../common/ArrowComponent/ArrowComponentV2";
import CustomImageComponent from '../../common/CustomImage/CustomImageComponent';
import DottedGrid from "../../common/Utils/DottedGrid";
import styles from "./HowToInstallAppComponentV2.module.css"
import {multipleClassName} from "../../../lib/utils/helpers/helper";

const HowToInstallAppComponentV2 = (props: IHowToInstallAppComponentProps) => {

    const {howToInstallData} = props;
    if(!howToInstallData){
      return <></>
    }
    const stepsData = howToInstallData.stepsDataV2
    const settings = {
        dots: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerPadding: "0px",
        nextArrow: <NextArrowV2 onClick/>,
        prevArrow: <PrevArrowV2 onClick/>,
        infinite: false,
        responsive: [
            {
                breakpoint: 757,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                    arrows: false,
                    dotsClass: "rating-slick-dots slick-dots slick-thumb",
                    appendDots: dots => (
                        <div
                            style={{
                                position: "static",
                            }}
                        >
                            <ul style={{margin: "0px"}}> {dots} </ul>
                        </div>
                    ),
                    customPaging: i => (
                        <div
                            style={{
                                width: dynamicCssSize(45, 13),
                                height: dynamicCssSize(7, 2),
                                backgroundColor: "rgba(255,255,255,0.3)",
                                borderRadius: "4px"
                            }}
                        ></div>
                    )
                }
            }
        ]
    };
    return (
        howToInstallData && Object.keys(howToInstallData).length?
            <H2TrapeziumBG uniqueContainerId={"howToInstall_container"}>
                <div className={multipleClassName("container-fluid", styles.mainContainerHowToInstall)}>
                    <DottedGrid color={"#d8d8d8"} lines={3} cssMobileStyle={{
                        display: "none"
                    }} cssDesktopStyle={{
                        display: "block",
                        position: "absolute",
                        bottom: "0",
                        left: "3vw",
                        zIndex: 1000,
                    }}/>
                    <div className={"container"} id="id_howToInstall">
                        <div className={styles.headingParent}>
                            <h2 className={styles.heading}>
                                {howToInstallData.heading}
                            </h2>
                        </div>
                        <div className={styles.sliderContainer}>
                            <Slider {...settings}>
                                {
                                    stepsData.map((step, index) => {
                                        return (
                                            <div key={"step_" + index}>
                                                <div className={styles.howToInstallItem}>
                                                    <div className={styles.imageParent}>
                                                        <div className={styles.imageContainer}>
                                                            <CustomImageComponent
                                                                src={step.url}
                                                                alt="how to install steps"
                                                                objectFit={'cover'}
                                                                objectPosition={'50% 50%'}
                                                                layout={"fill"}
                                                                className={styles.howToInstallImageV2}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className={styles.howToInstallContent}>
                                                        <p className={styles.step}>{step.step}</p>
                                                        <p className={styles.desc}>{step.text}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Slider>
                        </div>

                    </div>
                </div>

            </H2TrapeziumBG> : <></>
    )
}

export default HowToInstallAppComponentV2;
