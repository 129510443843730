import React from "react";
import { IWinzoUSPComponentProps } from "../../../lib/types/components";
import styles from "./WhyWinzo.module.css";
import CustomImageComponent from "../../common/CustomImage/CustomImageComponent";

const WhyWinzo = (props: IWinzoUSPComponentProps) => {
  const winzoUspData = props.winzoUSPData;
  return (
    <div className={styles.parentContainer}>
      <div className={styles.bgImages} />
      <div className="container" style={{ position: "relative" }}>
        <div className={styles.waterMarkAndTextContainer}>
          <div className={styles.waterMarkNoWrap}>{winzoUspData.watermark}</div>
          <p className={styles.subHeading}>{winzoUspData.subheading}</p>
          <h2 className={styles.heading}>{winzoUspData.heading}</h2>
        </div>

        <div className={styles.descContainer}>
          {winzoUspData.imageData.map((item) => {
            return (
              <div className={styles.itemContainer}>
                <div className={styles.imgContainer}>
                  <CustomImageComponent
                    src={item.src}
                    className={styles.uspImg}
                    alt={"winzo-features"}
                    layout={"fill"}
                    objectFit="contain"
                    objectPosition={"center"}
                  />
                </div>
                <div className={styles.content}>
                  <p className={styles.text1}>{item.text1}</p>
                  <p className={styles.text2}>{item.text2}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default WhyWinzo;
