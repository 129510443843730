"use client"

import { useEffect, useMemo, useRef, useState } from "react";
import { IFixedDownloadButtonProps, IFloatingDownloadButtonComponentProps, } from "../../../lib/types/components";
import {
    APK_DOWNLOAD_AGAIN_DELAY,
    FIXED_DOWNLOAD_BUTTON_TYPE,
    GET_APP_FB_URL,
} from "../../../lib/utils/constants/GlobalConstants";
import {
    checkIsGetAppFb,
    isiOSDevice,
    isMobile,
    setIsHowToVideoAvailable,
} from "../../../lib/utils/constants/PagesConstants";
import FixedDownloadButtonV2 from "../FixedDownloadButton/FixedDownloadButtonV2";
import CustomImageComponent from "../CustomImage/CustomImageComponent";
import useIsIOS from "../../../lib/utils/helpers/useIsIOS";
import { DownloadButtonState, useDownloadContext } from "../../../lib/context/CommonContext";
import Analytics from "../../../lib/analytics/Analytics";
import { HowToStoryClose, HowToStoryCompleted, HowToStoryNextFrame, HowToStoryOpen, HowToStoryPrevFrame, HowToVideoClose, HowToVideoPlayback, MuteHowToVideo } from "../../../lib/analytics/AnalyticsEvents";
import styles from "./FloatingDownloadButtonComponentWithVideo.module.css"
import { multipleClassName } from "../../../lib/utils/helpers/helper";
import React from "react";

let howToInstallFlowCompleted = false

const FloatingDownloadButtonComponentWithStory = (
    props: IFloatingDownloadButtonComponentProps
) => {
    const {
        fixedDownloadButtonData,
        blinkTextDownload,
        blinkTextDownload2,
        pageName,
        isMute = false
    } = props;

    const videoTag = useRef<any[]>([])
    let isIos = useIsIOS()
    const isGetAppFb: boolean = checkIsGetAppFb(pageName);
    const [scrollPositionGame, setScrollPositionGame] = useState<boolean>(false);
    const [loadVideo, setLoadVideo] = useState<boolean>(false)
    const [showVideo, setShowVideo] = useState<boolean | null>(null);
    const [currentStoryIndex, setCurrentStoryIndex] = useState<number>(0)
    const [holdState, setHoldState] = useState<boolean>(false)
    const [animationState, setAnimationState] = useState<{ lastPlayTime: number, timeLeft: number }>({ lastPlayTime: new Date().getTime(), timeLeft: (props.howToInstallStory?.config.slideTime || 3) * 1000 })
    const { downloadButtonState, setDownloadButtonState } = useDownloadContext()
    const toggleOverflowProperty = () => {
        const html: HTMLHtmlElement | null = document.querySelector("html");
        if (html !== null) {
            html.style.overflowY = showVideo && !isiOSDevice ? "hidden" : "auto";
        }
    };

    useEffect(() => {
        let timer: NodeJS.Timeout | undefined = undefined
        // if (isGetAppFacebook) {
        if (downloadButtonState === DownloadButtonState.DownloadClicked) {
            if (showVideo === null) { setShowVideo(true); Analytics.getInstance().logEvent(HowToStoryOpen) }
            setScrollPositionGame(true)
        } else if (downloadButtonState === DownloadButtonState.Downloading) {
            if (!showVideo && showVideo === null) {
                setShowVideo(true);
                Analytics.getInstance().logEvent(HowToStoryOpen)
            }

            timer = setTimeout(() => {
                // setIsApkDownloading(false);
                setDownloadButtonState(DownloadButtonState.DownloadAgain)
            }, APK_DOWNLOAD_AGAIN_DELAY);
        }
        return () => clearTimeout(timer);
    }, [downloadButtonState]);

    const onScroll = (_event: any): void => {
        if (!isMobile) return;

        if (window.scrollY > 440) {
            setScrollPositionGame(true);
        } else if (window.scrollY <= 440) {
            setScrollPositionGame(false);
        }
    };

    // useEffect to call initial function when page is mounted on DOM
    useEffect(() => {
        setIsHowToVideoAvailable(true)
        setLoadVideo(true)
        let newState = { ...{ lastPlayTime: new Date().getTime(), timeLeft: (props.howToInstallStory?.config.slideTime || 3) * 1000 } }
        setAnimationState(newState)
        window.addEventListener("scroll", onScroll);
        props.howToInstallStory!.stories?.map(() => {
            videoTag.current.push(React.createRef())
        })
        return (): void => {
            window.removeEventListener("scroll", onScroll);
        };
    }, []);

    useEffect(() => {
        isMobile && toggleOverflowProperty();
        if (showVideo && isIos === false) {
            const html: HTMLHtmlElement | null = document.querySelector("html");
            if (html !== null) {
                html.style.scrollBehavior = "initial";
                isMobile && window.scrollTo({ top: 450, left: 0 });
            }
            if (videoTag.current[currentStoryIndex].current) {
                videoTag.current[currentStoryIndex].current.currentTime = 0;
                videoTag.current[currentStoryIndex].current.play();
            }
        } else {
            videoTag.current[currentStoryIndex].current?.pause();
        }
        return () => {
            videoTag.current[currentStoryIndex].current?.pause();
            const html: HTMLHtmlElement | null = document.querySelector("html");
            if (html !== null) {
                html.style.overflowY = "auto";
            }
        };
    }, [showVideo, videoTag.current]);


    const fixedDownloadBtnProps: IFixedDownloadButtonProps = {
        // apkUrl: apkUrl,
        // isApkDownloading: isApkDownloading,
        type: FIXED_DOWNLOAD_BUTTON_TYPE,
        fixedDownloadButtonData: fixedDownloadButtonData,
        // setIsApkDownloading: setIsApkDownloading,
        // setIsToastOpen: setIsToastOpen,
        // sendAnalytics: sendAnalytics,
        // abTestingData,
        // appVersions
        pageName: pageName
    };


    useEffect(() => {
        if (showVideo) {
            let interval;
            if (holdState) {
                clearInterval(interval);
                let newState = { ...{ lastPlayTime: animationState.lastPlayTime, timeLeft: (props.howToInstallStory!.config.slideTime! * 1000) - (new Date().getTime() - animationState.lastPlayTime) } }
                setAnimationState(newState)
            } else {
                interval = setInterval(() => {
                    handleNextClick()
                }, animationState.timeLeft);
            }
            return () => {
                clearInterval(interval);
            }
        }

    }, [currentStoryIndex, holdState, showVideo]);


    const getStoryBarClass = (index: number) => {
        if (currentStoryIndex === index) {
            return styles.active
        } else if (currentStoryIndex > index) {
            return styles.complete
        } else {
            return ''
        }
    }


    const handlePrevClick = () => {
        const newIndex = currentStoryIndex - 1;

        if (newIndex < 0) {
            setCurrentStoryIndex(props.howToInstallStory!.stories.length -1);
        } else {
            setCurrentStoryIndex(newIndex);
        }
        let newState = { lastPlayTime: new Date().getTime(), timeLeft: props.howToInstallStory!.config.slideTime! * 1000 }
        setAnimationState(newState)
        videoTag.current[currentStoryIndex].current?.play();
        Analytics.getInstance().logEvent(HowToStoryPrevFrame)
    }

    const handleNextClick = () => {
        const newIndex = currentStoryIndex + 1;

        if (newIndex === props.howToInstallStory!.stories.length) {
            setCurrentStoryIndex(0);
            if (!howToInstallFlowCompleted) {
                Analytics.getInstance().logEvent(HowToStoryCompleted)
                howToInstallFlowCompleted = true
            }
        } else {
            setCurrentStoryIndex(newIndex);
        }
        let newState = { lastPlayTime: new Date().getTime(), timeLeft: props.howToInstallStory!.config.slideTime! * 1000 }
        setAnimationState(newState)
        videoTag.current[currentStoryIndex].current?.play();
        Analytics.getInstance().logEvent(HowToStoryNextFrame)
    }

    const handleVideoClick = (event) => {
        event.preventDefault();
        if (event.clientX > window.innerWidth * (props.howToInstallStory!.config.previousSlideClickPercentage / 100)) {
            handleNextClick()
        } else {
            handlePrevClick()
        }
    }
    const handleStoryPause = (event) => {
        event.preventDefault();
        setHoldState(true)
        videoTag.current[currentStoryIndex].current?.pause();
    }

    const handleStoryPlay = (event) => {
        event.preventDefault();
        setHoldState(false)
        videoTag.current[currentStoryIndex].current?.play();
    }


    const getStoryStateClass = () => {
        return holdState ? styles.storyAnimationPaused : styles.storyAnimationRunning
    }

    return !isGetAppFb && blinkTextDownload ? (
        <div
            className={multipleClassName(styles.floatingDownloadButtonContainer, !isiOSDevice ? styles.floatingDownloadButtonContainerStory : '')}
            style={scrollPositionGame || showVideo ? { bottom: "0px" } : { bottom: "-180px" }}
        >
            <div
                className={styles.floatingDownloadButton}
                style={{ display: showVideo && !isiOSDevice ? "block" : "none" }}
            >
                <div style={{ height: "100%" }}>
                    <div className={styles.howToInstallLabel}>{props.howToInstallStory?.label}</div>
                    <div
                        className={styles.offerPopupClose}
                        style={{ top: "18px", right: "10px" , width: "50px", height: "50px"}}
                        onClick={() => {
                            Analytics.getInstance().logEvent(HowToStoryClose)
                            setShowVideo(false);
                            setScrollPositionGame(false)
                        }}
                    >
                        <CustomImageComponent
                            src={
                                "https://d3g4wmezrjkwkg.cloudfront.net/website/images/cross.webp"
                            }
                            width={50}
                            height={50}
                            alt="offer-close-popup"
                        />
                    </div>
                    {loadVideo &&
                        props.howToInstallStory!.stories?.length != 0 && (
                            <div className={styles.storyContainer}>
                                <div className={styles.storyBarContainer}>
                                    {props.howToInstallStory!.stories.map((story, index) => {
                                        return <span className={styles.storyBar} key={index}
                                            style={{ width: `${100 / props.howToInstallStory!.stories.length}%` }}><span className={multipleClassName(getStoryBarClass(index), getStoryStateClass())}>
                                            </span></span>
                                    })
                                    }
                                </div>

                                {props.howToInstallStory!.stories?.map((story, index) => {
                                    return <div
                                        key={index}
                                        style={{ width: "100%", height: "100%", zIndex: 8, position: "absolute", display: currentStoryIndex === index ? "block" : "none" }}
                                        onClick={(event) => {
                                            handleVideoClick(event)
                                        }}
                                        onPointerDown={(event) => {
                                            handleStoryPause(event)
                                        }}
                                        onTouchMove={(event) => {
                                            handleStoryPlay(event)
                                        }}
                                        onPointerUp={(event) => {
                                            handleStoryPlay(event)
                                        }}
                                    >
                                        <video
                                            poster={story.thumbnail}
                                            src={story.url}
                                            className={styles.videoView}
                                            preload="none"
                                            loop
                                            ref={videoTag.current[index]}
                                            playsInline
                                            muted={isMute}
                                            style={{ pointerEvents: "none" }}
                                            key={index}
                                            controls={false}
                                            disablePictureInPicture
                                        />
                                    </div>
                                })}
                            </div>
                        )
                    }
                    {/* <div
                        className={styles.videoSoundControlView}
                        onClick={() => setMute(!mute)}
                    >
                        <CustomImageComponent
                            src={
                                mute
                                    ? "https://d3g4wmezrjkwkg.cloudfront.net/website/images/mute.webp"
                                    : "https://d3g4wmezrjkwkg.cloudfront.net/website/images/unmute.webp"
                            }
                            width={36}
                            height={36}
                            alt="offer-close-popup"
                        />
                    </div> */}
                </div>
            </div>
            {isiOSDevice ? (
                <p className={styles.floatingDownloadButtonText}>
                    {blinkTextDownload}
                </p>
            ) : (
                <p className={styles.floatingDownloadButtonTextStory}>
                    {downloadButtonState === DownloadButtonState.DownloadAgain
                        ? blinkTextDownload2 || "File didn’t download?"
                        : blinkTextDownload}
                </p>
            )}

            <FixedDownloadButtonV2 {...fixedDownloadBtnProps} storyFlow={true} />
        </div>
    ) : (
        <></>
    );
};

export default FloatingDownloadButtonComponentWithStory;
