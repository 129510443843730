import React from "react";
import styles from "./FaqComponent.module.css";
import parse from "html-react-parser";
import { multipleClassName } from "../../../lib/utils/helpers/helper";
import { IPageFAQComponentProps } from "../../../lib/types/components";

const FaqComponent = (props: IPageFAQComponentProps) => {
  const { pageFAQData } = props;

  return pageFAQData ? (
    <div className={styles.root}>
      <div className={styles.wedgeTop} />
      <div className={multipleClassName("container", styles.mainContainer)}>
        <div className={styles.waterMarkAndTextContainer}>
          <div className={styles.waterMarkNoWrap}>{pageFAQData.waterMark}</div>
          <p className={styles.subHeading}>{pageFAQData.subHeading}</p>
          <h2 className={styles.heading}>{pageFAQData.heading}</h2>
        </div>
        <div className={multipleClassName("row", styles.faqParentContainer)}>
          <div className="col" style={{ padding: 0 }}>
            {pageFAQData.faqs.map((faq, i) => {
              return (
                <div
                  className={styles.faqContainer}
                  key={"pageFAQData.faqs_" + i}
                >
                  <input
                    className={styles.input}
                    type="checkbox"
                    id={`${pageFAQData.heading}-faq-${i + 1}`}
                  />
                  <label
                    className={styles.label}
                    htmlFor={`${pageFAQData.heading}-faq-${i + 1}`}
                  >
                    <h3 className={styles.h3}>
                      <p className={styles.faqTitle}>
                        {i + 1 + ". "}
                        {faq.title}
                      </p>
                    </h3>
                  </label>
                  <div className={styles.para}>
                    {faq.paragraphs.map((item, index) => {
                      return <p key={index}>{parse(item)}</p>;
                    })}
                    {faq?.points && (
                      <ul>
                        {faq.points.map((item, index) => {
                          return <li key={"faq_points_" + index}>{item}</li>;
                        })}
                      </ul>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={styles.wedgeBottom} />
    </div>
  ) : (
    <></>
  );
};

export default FaqComponent;
