"use client"
import { useState } from 'react';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import H2TrapeziumBG from "../TrapeziumBackground/H2TrapeziumBG";
import { IWinzoVideoComponentProps } from '../../../lib/types/components';
import CustomImageComponent from '../CustomImage/CustomImageComponent';
import styledComponent from './youtubeVideo';
import useIsMobile from "../../../lib/customHooks/isMobile";
import styles from "./WinzoVideoComponentV2.module.css"
import {multipleClassName} from "../../../lib/utils/helpers/helper";

const WinzoVideoComponentV2 = (props: IWinzoVideoComponentProps) => {

    const {videosData} = props;

    const [videoId, setVideoId] = useState((videosData?.videoList && videosData.videoList[0].videoId) || '');
    const {isMobileDevice} = useIsMobile()

    return (
        isMobileDevice === false && videosData && Object.keys(videosData).length ?
            <div className={styles.removeMobile}>
                <style jsx>{styledComponent}</style>
                <H2TrapeziumBG uniqueContainerId={"winzoVideos_container"}>
                    <div className={styles.mainContainer} id="id_game_videos">
                        <h2 className={styles.headComponent}>{videosData.heading}</h2>
                        <div className={multipleClassName("container", styles.videoContainer)}>
                            <div className="row">
                                <div className={styles.videoMain}>
                                    <LiteYouTubeEmbed
                                        id={videoId}
                                        adNetwork={false}
                                        playlist={false}
                                        playlistCoverId={videoId}
                                        poster="hqdefault"
                                        title="WinZO Games Youtube Channel"
                                        noCookie={true}
                                        webp={true}
                                    />
                                </div>
                            </div>
                            <div className={multipleClassName("row", styles.sliderContainer)}>
                                <div className={styles.slider}>
                                    {
                                        videosData.videoList.map((item, index) => {
                                            return (
                                                <div className={styles.slideComponent} key={"videosData.videoList_" + index} onClick={() => setVideoId(item.videoId)}>
                                                    <div className={styles.slideDivComponent}>
                                                        <CustomImageComponent
                                                            objectFit={"cover"}
                                                            layout={'fill'}
                                                            objectPosition={"center"}
                                                            src={item.src}
                                                            alt={"winzo-videos"}
                                                        />
                                                    </div>
                                                    <h3 className={styles.subHeading}>{item.text1}</h3>
                                                    <p className={styles.paraComponent}>{item.text2}</p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </H2TrapeziumBG>
            </div>
             : <></>
    )
}

export default  WinzoVideoComponentV2;