import H2TrapeziumBG from "../../common/TrapeziumBackground/H2TrapeziumBG";
import {IPopularGamesComponentProps} from '../../../lib/types/components';
import {isiOSDevice} from "../../../lib/utils/constants/PagesConstants";
import CustomLinkComponent from "../../common/CusotmLink/CustomLinkComponent";
import CustomImageComponent from '../../common/CustomImage/CustomImageComponent';
import ButtonClickAnimation from "../../animation/ButtonClickAnimation";
import React from "react";
import dynamic from "next/dynamic";
import styles from "./PopularGamesComponentV2.module.css"
import {multipleClassName} from "../../../lib/utils/helpers/helper";

const PopularGamesSliderDesktopLazy = dynamic(() => import("./PopularGamesSliderDesktop"), {
    ssr: true
});

const PopularGamesComponentV2 = (props: IPopularGamesComponentProps) => {
    const {popularGamesData, winzoTagLineProps} = props;

    return (
        popularGamesData && Object.keys(popularGamesData).length ?
            <H2TrapeziumBG>
                <div className={multipleClassName("container-fluid", styles.parentContainer)}>
                    <div className={multipleClassName("container", styles.mainContainer)}>
                        <div className={multipleClassName("row", styles.heading)}>
                            <h2>{popularGamesData.heading}</h2>
                        </div>
                        <div style={{marginBottom: 20}}>
                            <h1 className={multipleClassName("col", styles.tagline)}>
                                {winzoTagLineProps.WinzoTagLineData}
                            </h1>
                        </div>
                        <div className="row">
                            <div className={multipleClassName("col", styles.sliderParent)}>
                                {<PopularGamesSliderDesktopLazy {...popularGamesData} />}
                                <div className={styles.mobileInnerSliderContainer}>
                                    {
                                        popularGamesData.games.map((item, index) => {
                                            return (
                                                <ButtonClickAnimation key={"popularGamesData.games_" + index}>
                                                    <CustomLinkComponent href={item.url}
                                                                         mobStyle={{textDecoration: "none", color: "white"}}
                                                                         desktopStyle={{textDecoration: "none", color: "white"}}
                                                    >
                                                        <div className={styles.mobImgContainer}>
                                                            <CustomImageComponent
                                                                layout={'fill'}
                                                                src={item.src}
                                                                alt={`Play ${item.name} Online`}
                                                            />
                                                        </div>

                                                        <p className={styles.mobGameName}>{item.name}</p>
                                                    </CustomLinkComponent>
                                                </ButtonClickAnimation>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </H2TrapeziumBG> : <></>
    )
}

export default PopularGamesComponentV2;