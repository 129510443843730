"use client"
import {IDownloadAppComponentProps} from '../../../lib/types/components';
import {getAppleAppUrl, getCountryFromLocale, isMobile} from '../../../lib/utils/constants/PagesConstants';
import CustomImageComponent from '../CustomImage/CustomImageComponent';
import styles from "./DownloadAppComponentV2.module.css"
import {multipleClassName} from "../../../lib/utils/helpers/helper";
import { useDownloadContext } from '../../../lib/context/CommonContext';

const DownloadAppComponentV2 = (props: IDownloadAppComponentProps) => {

    const {downloadAppData} = props;
    const {
        locale,
        androidUrl
    } = useDownloadContext();

    return (
        downloadAppData ?
            <div className={multipleClassName("container-fluid", styles.downloadContainer)}>
                <div className={styles.headingMobContainer}>
                    <h2 className={styles.headingMob}>
                        {downloadAppData.heading}
                    </h2>
                </div>
                <div>
                    <CustomImageComponent
                        src={isMobile ?
                            "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/downloadAppMobBack.webp"
                            : "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/donwloadAppBackground.webp"
                        }
                        layout={"fill"}
                        objectFit={"cover"}
                        alt={"sponsor-background"}
                    />
                </div>
                <div className={"container"}>
                    <div className={multipleClassName("row", styles.styledRow)}>
                        <div className={multipleClassName("col", styles.mobileImgContainer)}>
                            <CustomImageComponent
                                src={downloadAppData.mobileImgUrl}
                                layout={"fill"}
                                objectFit={"contain"}
                                alt="mobile_winzo_image"
                            />
                        </div>
                        <div className={multipleClassName("col", styles.styledCol)}>
                            <h2 className={styles.headingDesk}>
                                {downloadAppData.heading}
                            </h2>
                            <div className={styles.listContainer}>
                                <ul>
                                    {

                                        downloadAppData.list.map((item, index) => {
                                            return (
                                                <li key={"downloadAppData_" + index}>
                                                    {item}
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            <div className={multipleClassName("row", styles.downloadImgContainer)}>
                                {
                                    downloadAppData.downloadImg.map((item, index) => {
                                        return (
                                            <div key={"downloadImg_" + index} className={styles.imgContainer}>
                                                <div className={multipleClassName("col", styles.downloadImg)}
                                                     id={index === 0 ? "android_download_button" : "ios_download_button"}>
                                                    <a
                                                        href={index === 0 ? androidUrl : getAppleAppUrl(getCountryFromLocale(locale))}
                                                        suppressHydrationWarning
                                                    >
                                                        <CustomImageComponent
                                                            layout={"fill"}
                                                            objectFit="contain"
                                                            objectPosition={"center"}
                                                            src={item.url}
                                                            alt={"download-image"}
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            </div> : <></>
    )
}

export default DownloadAppComponentV2;