import React from "react";
import styles from "./PaymentPartnerComponent.module.css";
import { IBannerBottom } from "../../../lib/types/common";
import CustomImageComponent from "../../common/CustomImage/CustomImageComponent";

const PaymentPartnerComponent = (props: IBannerBottom) => {
  return (
    <>
      <div className={styles.topContainer} />
      <div className={styles.parentContainer}>
        <div className="container">
          <h2 className={styles.heading}>{props.text1}</h2>
          <div className={styles.imageContainer}>
            {props.paymentPartner && (
              <CustomImageComponent
                src={props.paymentPartner}
                alt="payment partner"
                objectFit={"contain"}
                objectPosition={"center center"}
                layout="fill"
              />
            )}
          </div>
        </div>
      </div>
      <div className={styles.bottomContainer} />
    </>
  );
};

export default PaymentPartnerComponent;
