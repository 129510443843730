"use client"

import React, {useEffect, useState} from 'react';
import Analytics from '../../../lib/analytics/Analytics';
import {getPopupEvent, OfferPopupCardClick} from '../../../lib/analytics/AnalyticsEvents';
import {OFFER_POPUP_DELAY} from '../../../lib/utils/constants/GlobalConstants';
import {getCountryFromLocale, isMobile, showToggle} from '../../../lib/utils/constants/PagesConstants';
import CustomImageComponent from '../CustomImage/CustomImageComponent';
import useWindowScrolling, {ScrollState} from "../../../lib/customHooks/windowScrolling";
import styles from "./OfferPopupComponent.module.css"
import {COUNTRY} from "../../../lib/utils/constants/LocaleAndCountryConstants";
import {DownloadButtonState, useDownloadContext} from "../../../lib/context/CommonContext";

const OfferPopupComponent = () => {
    const {
        locale,
        downloadButtonState,
        downloadUrl: apkUrl
    } = useDownloadContext();
    
    const showOfferPopup: boolean = getCountryFromLocale(locale) === COUNTRY.INDIA && showToggle();

    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [isApkDownloading, setIsApkDownloading] = useState<boolean>(false);
    const scrollState = useWindowScrolling()

    const handleCancelClick = (e) => {
        setShowPopup(false);
        if (sessionStorage)
          sessionStorage.setItem("disableInactivePopUp", "true");
        const html: any = document.querySelector("html");
        html.style.overflowY = "auto";
    }

    const handleClick = () => {
        Analytics.getInstance().logEvent(OfferPopupCardClick);
    }

    useEffect(() => {
        if (downloadButtonState === DownloadButtonState.Downloading) {
            setIsApkDownloading(true)
        }
    }, [downloadButtonState])

    useEffect(() => {
        if (sessionStorage && sessionStorage.getItem("disableInactivePopUp")) return ;
        let timer: NodeJS.Timeout | undefined = undefined;
        if (showOfferPopup && scrollState === ScrollState.Idle) {
            timer = setTimeout(() => {
                if (isMobile && !isApkDownloading) {
                    setShowPopup(true);
                    Analytics.getInstance().logEvent(getPopupEvent());
                    const html: HTMLHtmlElement | null = document.querySelector("html");
                    if (html !== null) {
                        html.style.overflowY = "hidden";
                    }
                }
            }, OFFER_POPUP_DELAY)
        } else {
            clearTimeout(timer)
        }
        return () => clearTimeout(timer);
    }, [scrollState, isApkDownloading])

    return (
        showPopup ?
            <div
                className={showPopup ? ((navigator.userAgent.indexOf("Firefox") !== -1) ? styles.containerOpenFirefox : styles.containerOpen) : styles.containerClose}
                onClick={handleCancelClick}
            >
                <div className={styles.popupContainer}>
                    <div
                        className={styles.modalContainer}
                        onClick={handleClick}
                    >
                        <a href={apkUrl} suppressHydrationWarning>
                            <div className={styles.bonusContainer}>
                                <CustomImageComponent
                                    src={`https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/offer_pop_up_${locale}.webp`}
                                    layout={"fill"}
                                    alt="Bonus_img"
                                />
                            </div>
                        </a>
                    </div>
                    <div className={styles.cancelButtonContainer} onClick={handleCancelClick}>
                        <CustomImageComponent
                            src={'https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/close_offer_popup.svg'}
                            width={32}
                            height={32}
                            layout={"fixed"}
                            alt="offer-close-popup"
                        />
                    </div>
                </div>
            </div> : <></>
    )

}

export default OfferPopupComponent;
