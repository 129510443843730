"use client";
import React, { useRef, useState } from "react";
import { IOurInfluencersData } from "../../../lib/types/home";
import styles from "./OurInfluencers.module.css";
import Slider, { Settings } from "react-slick";
import useIsMobile from "../../../lib/customHooks/isMobile";
import CustomCarousel from "../../common/CustomCarouselNaviagtion/CustomCarouselNavigation";
import Fancybox from "../../common/GDC/Fancybox";
import Link from "next/link";
import CustomImageComponent from "../../common/CustomImage/CustomImageComponent";
import { multipleClassName } from "../../../lib/utils/helpers/helper";

const OurInfluencers = (props: IOurInfluencersData) => {
  const { isMobileDevice } = useIsMobile();
  const [nextSlide, setNextSlide] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef<Slider>(null);

  const sliderSettings: Settings = {
    centerMode: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerPadding: "0px",
    dots: false,
    arrows: false,
    pauseOnHover: false,
    autoplay: true,
    speed: 500,
    beforeChange: (currentSlide, nextSlide) => {
      setNextSlide(nextSlide);
    },
    afterChange: (currentSlide) => {
      setCurrentSlide(currentSlide);
    },
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          centerPadding: "100px",
          autoplay: false,
          centerMode: true,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          centerPadding: "80px",
          autoplay: false,
          centerMode: true,
        },
      },
      {
        breakpoint: 330,
        settings: {
          slidesToShow: 1,
          centerPadding: "60px",
          autoplay: false,
          centerMode: true,
        },
      },
    ],
  };
  return props.influencerData ? (
    <div className={styles.parentContainer}>
      <div className={styles.bgImages} />
      <h2 className={styles.heading}>{props.heading}</h2>
      <div
        className={multipleClassName(
          styles.sliderParentContainer,
          "influencerSliderContainer"
        )}
      >
        <Fancybox
          options={{
            Carousel: {
              infinite: false,
            },
          }}
        >
          <Slider {...sliderSettings} ref={sliderRef}>
            {props.influencerData.map((item, idx) => {
              return idx !== nextSlide ? (
                <div key={"sliderData.videos_" + idx}>
                  <div className={styles.centerDiv}>
                    <div
                      className={styles.sliderContainer}
                      key={"about_gdc_" + idx}
                    >
                      <CustomImageComponent
                        src={item.thumbnail}
                        layout="fill"
                        alt={"about_gdc_"}
                      />
                      <div className={styles.influencerName}>
                        {item.videoStudioName}
                      </div>
                      <div className={styles.influencerDesc}>
                        {item.videoDataPara}
                      </div>
                      <div className={styles.playButton}>
                        <CustomImageComponent
                          src={
                            "https://d3g4wmezrjkwkg.cloudfront.net/website/images/brazilWebsite/play_button.webp"
                          }
                          layout="fill"
                          objectFit="contain"
                          objectPosition="center"
                          alt={"about_gdc_"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className={styles.centerDiv}>
                    <Link
                      data-fancybox
                      href={item.video}
                      key={"slide_videos_" + idx}
                      style={{ width: "auto", height: "1000px" }}
                    >
                      <div
                        className={multipleClassName(
                          styles.sliderContainer,
                          styles.centerContainer
                        )}
                      >
                        <CustomImageComponent
                          src={item.thumbnail}
                          layout="fill"
                          alt={"about_gdc_"}
                        />
                        <div className={styles.influencerName}>
                          {item.videoStudioName}
                        </div>
                        <div className={styles.influencerDesc}>
                          {item.videoDataPara}
                        </div>
                        <div className={styles.playButton}>
                          <CustomImageComponent
                            src={
                              "https://d3g4wmezrjkwkg.cloudfront.net/website/images/brazilWebsite/play_button.webp"
                            }
                            layout="fill"
                            objectFit="contain"
                            objectPosition="center"
                            alt={"about_gdc_"}
                          />
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              );
            })}
          </Slider>
        </Fancybox>

        <div className={styles.CustomCarouselNaviagtion}>
          <CustomCarousel
            nextSlide={nextSlide}
            currentSlide={currentSlide}
            slideCount={props.influencerData.length}
            sliderRef={sliderRef}
            hideArrow={isMobileDevice}
            customDotColor="#280042"
            customArrowColor="#280042"
            customArrowImage="https://d3g4wmezrjkwkg.cloudfront.net/website/images/brazilWebsite/right_dark_active_arrow.png"
          />
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default OurInfluencers;
