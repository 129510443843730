import CustomImageComponent from "../CustomImage/CustomImageComponent";
import useIsMobile from "../../../lib/customHooks/isMobile";
import styles from "./ArrowComponentV2.module.css"

export const PrevArrowV2 = ({onClick, showInMobile = false, left = "-40px", top = "50%",dark=false}) => {
    const {isMobileDevice} = useIsMobile()

    return (
        <div className={styles.arrow} style={isMobileDevice === true ? {
            display: `${showInMobile ? "flex" : 'none'}`,
            top: `${top || "50%"}`,
            left: `${left || "-40px"}`
        } : {
            top: `${top || "50%"}`,
            left: `${left || "-40px"}`
        }} onClick={onClick}>
            <CustomImageComponent
                width={17}
                height={37}
                src={dark?"https://d3g4wmezrjkwkg.cloudfront.net/website/images/bharattech-season2/left.svg":"https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/prevArrowV2.svg"}
                alt={"winner-quotes-image"}
            />
        </div>
    )
}

export const NextArrowV2 = ({onClick, showInMobile = false, right = "-40px", top = "50%",dark=false}) => {
    const {isMobileDevice} = useIsMobile()

    return (
        <div className={styles.arrow} style={isMobileDevice === true ? {
            display: `${showInMobile ? "flex" : 'none'}`,
            top: `${top || "50%"}`,
            right: `${right || "-40px"}`
        } : {
            top: `${top || "50%"}`,
            right: `${right || "-40px"}`
        }} onClick={onClick}>
            <CustomImageComponent
                width={17}
                height={37}
                src={dark?"https://d3g4wmezrjkwkg.cloudfront.net/website/images/bharattech-season2/right.svg":"https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/nextArrowV2.svg"}
                alt={"image"}
            />
        </div>
    )
}
